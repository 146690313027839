@import url('https://fonts.googleapis.com/css?family=Noto+Sans+TC&display=swap');
@import './variable';

/* ----- tag custom start ----- */
*,
*::before,
*::after {
  box-sizing: border-box;
}

[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}

::-webkit-scrollbar {
  width: 10px;

  &:horizontal {
    height: 8px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: none;
  &:horizontal {
    border: none;
  }
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b00909;
  border-radius: 20px;
  &:horizontal {
    background: #000000a3;
  }
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #950303;
  &:horizontal {
    background: black;
  }
}

:root {
  font-family: 'Noto Sans TC', 'Microsoft JhengHei', 'Roboto', sans-serif;
  font-size: 1rem;
}

body {
  line-height: 1.6;
  color: #4f4f4f;
  margin: 0;
  font-weight: 400;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

$link-color: $primary;
a {
  color: $link-color;
  text-decoration: none;

  &:hover {
    color: darken($link-color, 15%);
  }

  &.no-color {
    color: #4f4f4f;
    &:hover {
      color: darken(#4f4f4f, 15%);
    }
  }

  &.white {
    color: white;
    &:hover {
      color: #e1e1e1;
    }
  }
}

button:focus {
  outline: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  // line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}
/* ----- tag custom stop ----- */

#app-content {
  min-height: 90vh;
  width: 100%;
  margin: 4rem 0 0;
  &.dark {
    background-color: #021b42;
  }
  &.bottom-space {
    padding-bottom: 5rem;
  }
  &.with-second-nav {
    padding-top: 4.7rem;
  }
}

.formal-app-content {
  padding: 4.7rem 0 0;
}

.App {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-height: 100%;
  overflow: hidden;
  position: relative;
}
