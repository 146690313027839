@import url(https://fonts.googleapis.com/css?family=Noto+Sans+TC&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,*::before,*::after{box-sizing:border-box}[tabindex='-1']:focus:not(:focus-visible){outline:0 !important}::-webkit-scrollbar{width:10px}::-webkit-scrollbar:horizontal{height:8px}::-webkit-scrollbar-track{box-shadow:none}::-webkit-scrollbar-track:horizontal{border:none}::-webkit-scrollbar-thumb{background:#b00909;border-radius:20px}::-webkit-scrollbar-thumb:horizontal{background:#000000a3}::-webkit-scrollbar-thumb:hover{background:#950303}::-webkit-scrollbar-thumb:hover:horizontal{background:black}:root{font-family:'Noto Sans TC', 'Microsoft JhengHei', 'Roboto', sans-serif;font-size:1rem}body{line-height:1.6;color:#4f4f4f;margin:0;font-weight:400;background-color:#fff;-webkit-text-size-adjust:100%;-webkit-tap-highlight-color:rgba(0,0,0,0)}hr{margin:1rem 0;color:inherit;background-color:currentColor;border:0;opacity:0.25}a{color:#be3939;text-decoration:none}a:hover{color:#832727}a.no-color{color:#4f4f4f}a.no-color:hover{color:#292929}a.white{color:white}a.white:hover{color:#e1e1e1}button:focus{outline:0}label{display:inline-block}button{border-radius:0}input,button,select,optgroup,textarea{margin:0;font-family:inherit;font-size:inherit}button,input{overflow:visible}button,select{text-transform:none}select{word-wrap:normal}table{caption-side:bottom;border-collapse:collapse}#app-content{min-height:90vh;width:100%;margin:4rem 0 0}#app-content.dark{background-color:#021b42}#app-content.bottom-space{padding-bottom:5rem}#app-content.with-second-nav{padding-top:4.7rem}.formal-app-content{padding:4.7rem 0 0}.App{-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;text-align:center;min-height:100%;overflow:hidden;position:relative}

